import React from 'react'

import devFeatureToggle from '../../devFeatureToggle.json'
import { account } from '../../store.config'
import { ContentClubInformations } from '../local_modules/components/common/ContentClubInformations'
import { NavigationInstitutional } from '../local_modules/components/ui/NavigationInstitutional'
import { textClubAccount } from '../local_modules/constants'

import '../styles/pages/club-informacao.scss'

const ClubInformacao = () => {
  // prevent content
  if (!devFeatureToggle.hasClubRegister) {
    return null
  }

  return (
    <div className="club-container grid-content">
      <NavigationInstitutional titleText={textClubAccount(account)} />
      <div className="club-informations-container">
        <ContentClubInformations page="club-information" />
      </div>
    </div>
  )
}

// redirect to 404
export const getServerData = async () => {
  if (devFeatureToggle.hasClubRegister) {
    return {
      status: 200,
    }
  }

  const params = new URLSearchParams({
    from: encodeURIComponent(`/club-informacao`),
  })

  return {
    status: 404,
    props: null,
    headers: {
      location: `/404/?${params.toString()}}`,
    },
  }
}

export default ClubInformacao
